import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('api/login', params);
}

export const saveCustomerInfo = (params) => {
    return axiosClient.post('api/updatedata', params);
}

export const getListCustomer = (params) => {
    return axiosClient.get(`api/getlist?name=${params?.name}&code=${params?.code}&serie=${params?.serie}&limit=${params.limit}&currentPage=${params.currentPage}`);
}

export const getInfoPower = (params) => {
    return axiosClient.get(`XLKDD_getDailyData?sNoList=2210576349&sDate=2023-11-21%2000:00:00`);
}
