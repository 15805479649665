import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Space, theme, DatePicker, Card } from 'antd';
const { Option } = Select;
const { RangePicker } = DatePicker;

const CaculatorPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [placeholder, setPlaceHolder] = useState('Tên khách hàng');
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const rangeConfig = {
        rules: [
            {
                type: 'array',
            },
        ],
    };
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const onChangeSelect = (value) => {
        console.log("select", value);
        switch (value) {
            case "name":
                setPlaceHolder("Tên khách hàng");
                break;
            case "code":
                setPlaceHolder("Mã khách hàng");
                break;
            case "serie":
                setPlaceHolder("Số serie");
                break;
        }
    }
    return (
        <div>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item
                            name={'option'}
                            initialValue="name"
                        >
                            <Select onChange={onChangeSelect}>
                                <Option value="name">Tìm theo tên</Option>
                                <Option value="code">Tìm theo mã</Option>
                                <Option value="serie">Tìm theo serie</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={'value'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập thông tin'
                                }
                            ]}
                        >
                            <Input placeholder={placeholder} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="range-picker" {...rangeConfig}>
                            <RangePicker placeholder={['Bắt đầu', 'Kết thúc']} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setPlaceHolder("Tên khách hàng");
                                }}
                            >
                                Clear
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Card title={"Khách hàng: Nguyễn Văn A"}>
                <p>Thời gian: 10/03/2023 - 20/03/2023</p>
                <p>Số điện sử dụng: 3243 Kwh</p>
            </Card>
        </div>

    );
};
export default CaculatorPage;