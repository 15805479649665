import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, theme, DatePicker, Table } from 'antd';
import LoadingBase from '../../common/LoadingBase';
import { getListCustomer } from '../../services/apis';
import './searchdata.css'
const { Option } = Select;

const SearchData = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [placeholder, setPlaceHolder] = useState('Tên khách hàng');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Mã',
            dataIndex: 'code',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'fullname',
        },
        {
            title: 'Số serie',
            dataIndex: 'serie',
        }
    ]
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const rangeConfig = {
        rules: [
            {
                type: 'array',
            },
        ],
    };

    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        const formData = form.getFieldsValue();
        getDataCustomer(formData, page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        const formData = form.getFieldsValue();
        getDataCustomer(formData, page - 1);
    }

    const getDataCustomer = async (data, currentPage) => {
        let params;
        if (data) {
            switch (data?.option) {
                case 'name':
                    params = {
                        limit: 20,
                        currentPage: currentPage,
                        name: data?.value ?? '',
                        code: '',
                        serie: ''
                    };
                    break;
                case 'code':
                    params = {
                        limit: 20,
                        currentPage: currentPage,
                        code: data?.value ?? '',
                        name: '',
                        serie: ''
                    };
                    break;
                case 'serie':
                    params = {
                        limit: 20,
                        currentPage: currentPage,
                        code: '',
                        name: '',
                        serie: data?.value ?? ''
                    };
                    break;
            }
            setLoading(true);
            const res = await getListCustomer(params);
            setLoading(false);
            if (res && res?.data) {
                setData([...res.data]);
                setTotalPage(res?.totalPages);
            } else {
                setData([]);
                setTotalPage(0);
            }
        }
    }
    const onFinish = async (data) => {
        console.log('Received values of form: ', data);
        setCurrentPage(1);
        getDataCustomer(data, 1);
    };
    const onChangeSelect = (value) => {
        console.log("select", value);
        switch (value) {
            case "name":
                setPlaceHolder("Tên khách hàng");
                break;
            case "code":
                setPlaceHolder("Mã khách hàng");
                break;
            case "serie":
                setPlaceHolder("Số serie");
                break;
        }
    }
    return (
        <div>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item
                            name={'option'}
                            initialValue="name"
                        >
                            <Select onChange={onChangeSelect}>
                                <Option value="name">Tìm theo tên</Option>
                                <Option value="code">Tìm theo mã</Option>
                                <Option value="serie">Tìm theo serie</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'value'}
                        >
                            <Input placeholder={placeholder} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    setPlaceHolder("Tên khách hàng");
                                }}
                            >
                                Clear
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table columns={columns} dataSource={data} pagination={false} />
            <div className='footer-table'>
                <Space size="small">
                    <Button type="link" disabled={currentPage > 1 ? false : true} onClick={() => handlePrev()}>
                        Trang trước
                    </Button>
                    <Button
                        disabled={currentPage < totalPage ? false : true}
                        type='link'
                        onClick={() => handleNext()}
                    >
                        Trang sau
                    </Button>
                </Space>
            </div>
            <LoadingBase loading={loading} />
        </div>

    );
};
export default SearchData;