import React, { useCallback, useEffect, useState } from 'react';
import './home-page.css'
import {
  CalculatorOutlined,
  FileSearchOutlined,
  ImportOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Menu, Upload, theme } from 'antd';
import { Images } from '../../assets';
import ImportExcel from '../import/ImportExcel';
import SearchData from '../search/SearchData';
import CaculatorPage from '../caculator/CaculatorPage';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Import dữ liệu', '1', <ImportOutlined />),
  getItem('Tra cứu', '2', <FileSearchOutlined />),
  // getItem('Tính số điện', '3', <CalculatorOutlined />),
  //   getItem('User', 'sub1', <UserOutlined />, [
  //     getItem('Tom', '3'),
  //     getItem('Bill', '4'),
  //     getItem('Alex', '5'),
  //   ]),
  getItem('Đăng xuất', '4', <LogoutOutlined />),
];
const HomePage = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [menuSelect, setMenuSelect] = useState(items[0]);

  const [loading, setLoading] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    Cookies.remove('authToken');
    Cookies.remove('authTokenExpiration');
    navigate("/login");
  }

  const renderComponent = useCallback((type) => {
    switch (type) {
      case "1":
        return <ImportExcel />
      case "2":
        return <SearchData />
      case "3":
        return <CaculatorPage />
      case "4":
        return handleLogout();
    }
  }, [menuSelect])

  const handleCLickMenu = (item) => {
    setMenuSelect(items.find(obj => obj.key === item.key));
  }

  useEffect(() => {
    // Kiểm tra cookie khi trang được tải
    const token = Cookies.get('authToken');
    console.log("token", token);
    if (token) {
      // Nếu cookie tồn tại, kiểm tra thời gian hết hạn
      const expirationTime = Cookies.get('authTokenExpiration');
      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime) {
        handleLogout();
      } 
    } else {
      // Token đã hết hạn, xử lý đăng xuất
      handleLogout();
    }
  }, []);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo-vertical" >
          <img src={Images.img_logo_app} className='img-logo' />
        </div>
        <Menu theme="dark" defaultSelectedKeys={[menuSelect.key]} mode="inline" items={items} onClick={handleCLickMenu} />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <p className='title-header'>Hệ thống quản lý thông tin khách hàng</p>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>{menuSelect.label}</Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {

              renderComponent(menuSelect.key)
            }
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Bản quyền thuộc ECOTVN ©2023
        </Footer>
      </Layout>
    </Layout>
  );
};
export default HomePage;