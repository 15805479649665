import axios from 'axios';
import { BASE_URL } from '../screens/settings/config';


const axiosClient = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 30000,
});
function apiLogger(msg, color, params) {
    console.log(msg, color, params);
}
axiosClient.interceptors.request.use(
    async config => {
        config.baseURL = BASE_URL;
        return config;
    },
    error => {
        apiLogger(
            `%c FAILED ${error.response.method?.toUpperCase()} from ${error.response.config.url
            }:`,
            'background: red; color: #fff',
            error,
        );
        return Promise.reject(error);
    },
);
axiosClient.interceptors.response.use(
    (res) => {
        console.log("response", res);
        return res.data
    },
    (er) => {
        console.log("er", er);
    }
);
export default axiosClient;
