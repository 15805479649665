import { ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Table, Upload } from "antd";
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import './import.css'
import { saveCustomerInfo } from "../../services/apis";
import LoadingBase from "../../common/LoadingBase";

const ImportExcel = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const [message, setMessage] = useState('');
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const [listFailed, setListFailed] = useState([]);
    const columns = [
        {
            title: 'STT',
            key: 'index',
            render: (text, record) => data.indexOf(record) + 1,
        },
        {
            title: 'Mã',
            dataIndex: 0,
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 1,
        },
        {
            title: 'Số serie',
            dataIndex: 2,
        },
    ]
    const confirm = () => {
        modal.confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có muốn lưu thông tin khách hàng không?',
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => handleSaveInfo()
        });
    };
    const handleSaveInfo = async () => {
        setLoading(true);
        const res = await saveCustomerInfo(data);
        setLoading(false);
        if (res) {
            if (res?.code === 1) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 2) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thành công');
                setListFailed(res.failedArr && res.failedArr.length > 0 ? [...res.failedArr] : []);
                setIsShowModalMessage(true);
                setData([]);
                setIsShowButton(false);
            } else if (res?.code === 0) {
                setMessage(res.msg ?? 'Cập nhật dữ liệu thất bại');
                setIsShowModalMessage(true);
            } 
            
        } else {
            setMessage('Có lỗi xảy ra. vui lòng thử lại');
            setIsShowModalMessage(true);
        }
    }
    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                console.log("dataParse", dataParse)
                if (dataParse && dataParse.length > 0) {
                    setData([...dataParse]);
                    setIsShowButton(true);
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };
    return (

        <div>
            {
                contextHolder
            }
            <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Button type="default" size="large" block danger icon={<ImportOutlined />} disabled={loadingImport} onClick={() => { }}
                        style={{ width: 150 }}
                    >
                        Import Excel
                    </Button>
                </Upload>
                {
                    isShowButton ?
                        <Button type="primary" size="large" defaultBg="#ff0000" block icon={<SaveOutlined />} disabled={loading} onClick={confirm}
                            style={{ width: 150 }}
                        >
                            Lưu thông tin
                        </Button> : null
                }

            </Row>
            {
                data.length > 0 ?
                    <p>Tổng: {data.length} khách hàng</p> : null
            }

            <Table columns={columns} dataSource={data} style={{ marginTop: 20 }} />
            <LoadingBase loading={loading} />
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Đóng
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                        color: 'blue',
                    }} /> Thông báo</p>

                    <p>{message}</p>
                    {
                        listFailed && listFailed.length > 0 ? 
                        <p>Các khách hàng chưa lưu được: {listFailed.join(', ')}</p> : null
                    }
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(ImportExcel);
